import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment.staging';
import { enableProdMode } from '@angular/core';

console.log('ENV: ', environment)
if (environment.production) {
  enableProdMode()
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
